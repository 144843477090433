@import "../constants";

.VideoRoom {
  height: 100vh;
  width: 100vw;
  position: relative;
  font-family: $primary_font_family;
  display: flex;
  flex-wrap: wrap;

  &__video {
    background: #000000;
    height: 100%;
    width: 100%;
    text-align: center;
  }

  &__sidebar {
    @include panel;

    position: absolute;
    bottom: 110px;
    left: 40px;
    z-index: $z_index_high;
    width: 320px;

    &-question {
      padding: 12px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      border-top: 1px solid #f0f0f0;
      font-family: $heading_font_family;
      font-size: 17px;
      font-weight: 500;
      cursor: pointer;
      width: 100%;

      &:focus,
      &:active {
        outline: 0;
      }
    }

    &-arrow {
      width: 9px;
      height: 11px;
      background: url("../images/triangle.svg") no-repeat;
      background-size: 11px 9px;
      transition: all 300ms ease-in-out;

      &--rotated {
        transform: rotate(90deg);
      }
    }
  }

  &__bottombar {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 40px;
    user-select: none;
  }

  &__center-buttons {
    display: flex;
    justify-content: center;
  }

  &__button-wrapper {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    color: #ffffff;
    opacity: 1;
    font-size: 15px;
    cursor: pointer;

    &:focus,
    &:active {
      outline: 0;
    }

    &--active {
      color: #ec534b;
    }
  }

  &__quick-info,
  &__exit-button,
  &__chat-button {
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 50px;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__quick-info {
    position: absolute;
    left: 40px;
    justify-content: space-between;
    width: 320px;
    color: #ffffff;
    padding: 0 30px;
  }

  &__instructions-toggle {
    &:focus,
    &:active {
      outline: 0;
    }
  }

  &__time-text {
    display: inline-block;
    min-width: 80px;
    padding-left: 8px;
  }

  &__exit-button {
    padding: 0 25px;
    color: #ec534b;
    cursor: pointer;
  }

  &__chat-button {
    position: absolute;
    right: 40px;
    padding: 0 25px;
    color: #ffffff;
    cursor: pointer;
  }

  &__facepile-button {
    position: absolute;
    right: 190px;
    align-items: center;
    height: 50px;
    cursor: pointer;
  }

  &__logo {
    position: absolute;
    top: 30px;
    left: 40px;
    opacity: 0.5;
    width: 100px;
  }
}
