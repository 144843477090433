@import './constants.scss';

.PromotionalListItem {
  background: #f1f3ff;

  &__button {
    background: $purple;
  }

  &__subtitle {
    width: 85%;
    padding-bottom: 2rem;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    &__body {
      display: flex;
      justify-content: space-between;
    }

    &__subtitle {
      width: 70%;
      padding-bottom: none;
    }
  }
}
