@import './constants.scss';

.UpvoteButton {
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  color: #888888;
  padding: 0 10px;
  margin: 0;
  margin-right: 4px;
  border: 0;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  transition: all 175ms ease-in-out;
  display: flex;
  align-items: center;
  white-space: nowrap;

  svg {
    margin-right: 6px;
    margin-left: -2px;

    path {
      fill: #666666;
    }
  }

  &:hover,
  &:focus {
    background: #f7f7f7;
    color: #333333;
  }

  &--voted,
  &--voted:hover,
  &--voted:focus {
    color: #f5c61c;
    background-color: rgba(255, 209, 46, 0.2);
    text-decoration: none;
    outline: 0;
    border: 0;

    svg path {
      fill: #f5c61c;
    }
  }

  &:focus,
  &:active {
    outline: 0;
  }

  &--large {
    font-size: 16px;
    border: 1px solid #dddddd;

    &:hover,
    &:focus,
    &.UpvoteButton--voted,
    &.UpvoteButton--voted:hover,
    &.UpvoteButton--voted:focus {
      border: 1px solid transparent;
    }
  }
}
