@import 'constants';

$tag_bg_purple: #faf9fe;
$tag_purple: #a282fd;
$testimonial_border_light_gray: #f3f4f6;
$section_border_gray: #e0e0e0;
$star_yellow: #f2c94c;

.CoachingProfilePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;

  &__inner {
    display: flex;
    width: 100%;
    max-width: 1196px;
    padding: 72px;

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      padding: 36px;
    }
  }

  &__profile-image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 30px;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      margin-bottom: 24px;
    }

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      width: 150px;
      height: 150px;
    }
  }

  &__name {
    font-size: 36px;
    font-weight: bold;
    color: $font_black;
  }

  &__title {
    font-size: 20px;
    color: $font_gray;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      margin-bottom: 24px;
    }
  }

  &__bio-purchase-row {
    display: flex;
    padding-bottom: 20px;
  }

  &__bio-section {
    @media (max-width: map-get($grid-breakpoints, 'md')) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
    }
  }

  &__bio {
    color: $font_gray;
  }

  &__featured-section {
    padding-top: 34px;
    padding-bottom: 34px;
    border-bottom: 1px solid $section_border_gray;
    color: $font_gray;
  }

  &__featured-link {
    display: block;
    margin-bottom: 4px;
    text-decoration: underline;

    &:hover,
    &:visited,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }

  &__header {
    font-size: 18px;
    font-weight: bold;
    color: $font_black;
    margin-bottom: 8px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  &__tag {
    padding: 12px 18px;
    border-radius: 10px;
    background-color: $tag_bg_purple;
    color: $tag_purple;
    font-weight: 500;
    font-size: 14px;
    margin: 0 16px 16px 0;
  }

  &__right-section {
    min-width: 600px;
  }

  &__purchase-section {
    flex-shrink: 0;
    margin-left: 50px;
  }

  &__testimonials-section {
    padding-top: 34px;
  }

  &__testimonial {
    padding-top: 23px;
    padding-bottom: 23px;

    &:not(:last-child) {
      border-bottom: 1px solid $testimonial_border_light_gray;
    }
  }

  &__session-number {
    color: $font_gray;
    font-weight: 600;
    font-size: 14px;
    margin-left: 6px;
    margin-top: 2px;
  }

  &__profile-stars {
    display: flex;
    margin-bottom: 25px;
    margin-left: -2px;
    align-items: center;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      margin-bottom: 8px;
    }
  }

  &__testimonial-stars {
    display: flex;
    margin-bottom: 8px;
    margin-left: -4px;
  }

  .star {
    margin: 0 3px;
    background-color: $star_yellow;
    -webkit-mask: url("./images/star.svg") no-repeat center;
    mask: url("./images/star.svg") no-repeat center;
    -webkit-mask-size: 16px 16px;
    mask-size: 16px 16px;
    display: inline-block;
    height: 16px;
    width: 16px;
  }
}
