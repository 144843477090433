@import './constants.scss';

@keyframes scaleIn {
  from {
    transform: translate(-50%, -40%);
    opacity: 0;
  }

  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.Modal {
  background: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: $z_index_highest;
  display: flex;
  flex-direction: column;
  box-shadow: 0 -3px 11px 0 rgba(0, 0, 0, 0.27);
  padding-top: 0;
  width: 100%;
  max-width: 550px;
  animation: scaleIn 300ms ease-in-out forwards;
  border-radius: 4px;
  font-family: $primary_font_family;

  &:focus {
    outline: 0;
  }

  &__buttons {
    display: flex;
    text-align: center;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;

    .Button {
      margin-right: 20px;
      margin-bottom: 10px;
      max-width: 250px;
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    // At very small widths, cover the whole screen
    min-width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
    max-height: none;
    overflow-y: scroll;
    border-radius: 0;
    animation: none;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: $z_index_highest;
    animation: fadeIn 500ms ease-in-out forwards;
    opacity: 0;
  }

  &__header {
    display: flex;
    background: #fafafa;
    border-bottom: 1px solid #eeeeee;
    font-size: 18px;
    font-weight: 600;
    padding: 20px 32px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  &__body,
  &__footer {
    padding: 20px 32px;
  }

  &__footer {
    padding-top: 0;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    padding-bottom: 20px;
  }
}
