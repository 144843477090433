@import "./constants";

.Button,
a.Button {
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 120px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  padding: 10px 26px;
  color: #ffffff;
  text-decoration: none;
  white-space: nowrap;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: center;

  svg,
  img {
    left: -10px;
    position: relative;
  }

  &--uppercase {
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 500;
    font-size: 14px;
  }

  &--rounded {
    border-radius: $border-lg;
  }

  &--full {
    width: 100%;
  }

  &--primary {
    background-color: lighten($purple, 2%);
    color: #ffffff;

    &:hover,
    &:visited,
    &:active {
      background-color: $purple;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
      color: #ffffff;
      text-decoration: none;
    }

    &:disabled {
      background-color: lighten($purple, 25%);
    }
  }

  &--secondary {
    background-color: darken($purple, 25%);
    color: #ffffff;

    &:hover,
    &:visited,
    &:active {
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
      color: #ffffff;
      text-decoration: none;
    }

    &:disabled {
      background-color: #bbbbbb;
    }
  }

  &--grey {
    background-color: lighten(#ececec, 2%);
    color: #000000;
    box-shadow: unset;

    &:hover,
    &:visited,
    &:active {
      background-color: #ececec;
      box-shadow: unset;
      color: #000000;
      text-decoration: none;
    }

    &:disabled {
      background-color: lighten(#ececec, 25%);
    }
  }

  &--outline {
    border: 1px solid $border_gray;
    color: $med_grey;
    background: none;
    box-shadow: none;

    &:hover,
    &:visited,
    &:active {
      color: $med_grey;
    }

    &:hover {
      filter: brightness(85%);
      background: none;
      box-shadow: none;
      text-decoration: none;
    }

    &:disabled {
      background-color: #bbbbbb;
    }
  }

  &__google,
  &__facebook,
  &__linkedin {
    color: black;
    background-color: white;
    box-shadow: none;
    border: solid 1px $border_gray;

    &:hover,
    &:visited,
    &:active {
      background-color: white;
      color: black;
      box-shadow: none;
    }

    &:hover {
      border-color: darken(#cccccc, 5%);
    }
  }

  &__logo {
    height: 22px;
    display: inline;
  }
}
