@import './constants';

$readonly_color: #e2e2e2;
$input_border_color: #dddddd;
$input_focused_border_color: #5630d948;

.TextFormField {
  input {
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &__input-field {
    padding: 16px 24px;
    border-radius: 6px;
    border: 1px solid $input_border_color;
    flex-grow: 1;
    box-sizing: border-box;

    &:read-only {
      background-color: $readonly_color;
    }

    &:focus:not(:read-only) {
      box-shadow: inset 0 0 0 4px $input_focused_border_color;
    }

    &:hover:not(:focus):not(:read-only) {
      box-shadow: 0 2px 2px rgba(189, 189, 189, 0.25);
    }
  }

  &__error {
    color: map-get($theme-colors, danger);
  }
}
