@import "./constants";

.AboutPage {
  &__header {
    text-align: center;
    padding: 30px 0;

    .AboutPage__heading {
      font-size: 40px;
      font-weight: 600;
      padding: 10px 0;
    }

    .AboutPage__subheading {
      font-size: 16px;
      color: $light_grey;
    }
  }

  &__container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 30px 0;
  }

  &__panel {
    @include panel;

    padding: 30px;
    margin: 10px 0;
  }

  .panel {
    &__profile {
      display: flex;
      padding: 10px 0;
      align-items: center;

      &_image {
        margin-right: 20px;
        height: 70px;
        width: 70px;
        border-radius: 40px;
        object-fit: cover;
      }

      &_info {
        display: inline-block;
      }
    }

    &__name {
      font-weight: 600;
      font-size: 18px;
      margin: 2px 0;
    }

    &__big-name {
      font-weight: 600;
      font-size: 26px;
      margin: 2px 0;
    }

    &__title {
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-size: 12px;
      margin: 2px 0;
    }

    &__text {
      color: $light_grey;
      font-size: 16px;
      padding: 15px 0;
    }

    &__dark-text {
      color: $dark_grey;
      font-size: 16px;
      padding: 15px 0;
    }

    &__social {
      text-align: right;
      margin-top: 10px;

      a {
        margin: 0 5px;
        font-size: 16px;
      }
    }
  }
}

img.center {
  display: block;
  margin: 0 auto;
}

div.svg-container {
  margin-bottom: 2.5em;
}
