@import './constants';

.EmailSignup {
  background: white;
  box-shadow: $shadow-lg;
  border-radius: 10px;
  width: 450px;
  padding: 30px 40px 30px;
  text-align: center;
  margin: 0 auto;

  &__submit {
    height: 50px;
    width: 100%;
    margin-top: 20px;
  }

  &__heading {
    font-size: 24px;
    font-weight: 500;
    font-family: $heading_font_family;
    display: inline-block;
    margin-bottom: 20px;
  }

  &__input {
    background: #ffffff;
    border: 1px solid #dedede;
    box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.07);
    border-radius: $border-lg;
    height: 50px;
    font-size: 18px;
    padding: 10px 20px;
  }
}
