@import "constants";

.UpgradeSuccessPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 50px;

  @media (max-width: map-get($grid-breakpoints, "md")) {
    justify-content: flex-start;
  }

  &__logo {
    margin-bottom: 56px;
    height: 28px;

    @media (max-width: map-get($grid-breakpoints, "md")) {
      height: 16px;
    }
  }

  &__header {
    color: $font_black;
    font-weight: bold;
    font-size: 60px;
    margin-bottom: 24px;

    @media (max-width: map-get($grid-breakpoints, "md")) {
      font-size: 40px;
    }
  }

  &__text {
    color: $font_gray;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 48px;
    max-width: 500px;
    text-align: center;

    @media (max-width: map-get($grid-breakpoints, "md")) {
      font-size: 16px;
    }
  }
}
