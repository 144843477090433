@import './constants';

.FormHeader {
  font-size: 26px;
  font-weight: 600;
  color: #000000;
}

.FormSubheader {
  font-size: 18px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 20px;
}
