@import "./constants";

.ReferralHelp {
  text-align: center;
  margin-top: 50px;

  &__instructions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .instructions-step {
      display: flex;
      flex-direction: column;
      align-items: left;
      flex: auto;
      padding-bottom: 32px;
      margin-left: 1rem;

      @media (min-width: map-get($grid-breakpoints, 'md')) {
        flex: 1;
      }

      .numberCircle {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1.5px solid #000000;
        width: 2em;
        height: 2em;
        padding: 0.25em;
        background: #ffffff;
        color: #000000;
        font-size: 18px;
        font-weight: 500;
      }

      .step-text {
        margin-top: 12px;
        font-weight: 400;
        font-size: 14px;
        padding: 0 8px;
        text-align: center;
      }
    }
  }
}
