@import './constants';

.MultiSelectQuestion,
.SingleSelectQuestion,
.TextAreaQuestion {
  margin: 20px 0;

  &__title {
    font-weight: 500;
    color: #000000;
    display: block;
    text-align: left;
    margin-bottom: 3px;
    font-size: 16px;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 500;
    color: #a0a0a0;
    margin-bottom: 6px;
  }

  &__label {
    display: flex;
    align-items: baseline;
    text-align: left;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;

    input {
      margin-right: 10px;
    }
  }

  &__textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1.5px solid #b7b7b7;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
  }
}

@mixin inputStackedStyle($selector) {
  .stacked#{$selector} #{$selector} {
    &__title {
      text-align: center;
      font-size: 1.25rem;
      font-weight: 600;
      color: rgba(31, 41, 55, 1);
      display: block;
      margin-bottom: 3px;
    }

    &__subtitle {
      text-align: center;
      font-size: 0.75rem;
      line-height: 1rem;
      color: rgba(156, 163, 175, 1);
      margin-bottom: 6px;
    }

    &__label {
      text-align: center;
      position: relative;
      display: block;
      border-radius: 0.5rem;
      align-items: baseline;
      border-width: 1px;
      border-color: rgba(209, 213, 219, 1);
      background-color: rgba(255, 255, 255, 1);
      box-shadow: none;
      padding: 1rem 1.5rem;
      color: #000000;
      font-weight: 400;
      font-size: 1rem;
      cursor: pointer;

      @media only screen and (max-width: 640px) {
        padding: 0.5rem 0.75rem;
      }

      &.active {
        box-shadow: 0 0 0 2px #8b5cf6, 0 0 #00000000;
      }

      &:hover {
        color: rgba(99, 102, 241, 1);
        border-color: rgba(156, 163, 175, 1);
      }

      &:focus {
        outline: none;
      }

      input {
        margin-right: 10px;
      }
    }

    &__radio {
      visibility: hidden;
    }

    &__checkbox {
      visibility: hidden;
    }
  }
}

@include inputStackedStyle(".SingleSelectQuestion");
@include inputStackedStyle(".MultiSelectQuestion");
@include inputStackedStyle(".TextAreaQuestion");

.FormErrorMessage {
  color: red;
  font-size: 10px;
}

.FormButton {
  width: auto;
  border-radius: 5px;
  transition: 0.2s ease-in;

  .Spinner {
    width: 16px;
    height: 16px;
    border-color: 6px;
  }
}

.FormButton[disabled] {
  color: #717171;
  background: #eeeeee;
}
