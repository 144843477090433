@import "./constants";

.Footer {
  background-color: #f9f9f9;
  padding: 40px 0;
  padding-bottom: 10px;
  color: #8a8a8e;
  font-size: 16px;

  &__inner {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    @media (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  &__section {
    margin: 25px;

    &__heading {
      color: #1c1b24;
      text-transform: uppercase;
      font-weight: bold;
      display: block;
      font-size: 12px;
      letter-spacing: 3px;
      margin-bottom: 14px;
      opacity: 0.9;
    }

    &__social {
      display: inline-block !important;

      a,
      svg {
        color: black;
        width: 20px;
        display: inline-block;
        height: 20px;
        margin-right: 10px;
        opacity: 0.8;
      }
    }

    a,
    a:visited,
    a:active {
      color: $light_grey;
      display: block;
      margin: 5px 0;
      opacity: 1;
      transition: ease 200ms;
    }

    a:hover {
      color: $dark_grey;
      opacity: 1;
    }
  }

  &__newsletter {
    flex-basis: 33%;

    &__form {
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 25px 0;

      @media (max-width: map-get($grid-breakpoints, "sm")) {
        flex-direction: column;
      }

      &--submitted {
        background: #cccccc;
        color: #999999;
      }
    }

    &__input {
      width: 100%;
      padding: 12px;
      font-size: 16px;
      box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.102273);
      border-radius: 4px;
      border: 1px solid #dedede;
      color: black;

      &:active,
      &:focus {
        outline: 0;
      }
    }

    &__submit {
      margin-left: 10px;

      @media (max-width: map-get($grid-breakpoints, "sm")) {
        margin: 10px 0;
      }

      flex-basis: 50%;
    }
  }

  &__logo {
    text-indent: -2000px;
    display: inline-block;
    text-align: center;
    color: black;
    width: 120px;
    height: 40px;
    background: url("./images/logotype_black.png") no-repeat;
    background-position: left center;
    background-size: 120px auto;
  }

  .copyright {
    display: block;
    text-align: left;
    padding: 0 15px;

    a,
    a:visited,
    a:active {
      color: #8a8a8e;
    }

    @media (max-width: 1199.98px) {
      padding: 0 25px;
    }

    @media (max-width: 1024px) {
      text-align: center;
    }
  }
}
