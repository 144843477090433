@import 'constants';

.UpgradePasswordPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px;

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    padding: 50px 30px 100px;
  }

  &__panel {
    padding: 50px;
    border-radius: 4px;
    border: 1px solid $border_gray;
    max-width: 600px;
    background: white;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      padding: 0;
      border: none;
      background: transparent;
    }
  }

  &__header {
    font-size: 24px;
    font-weight: bold;
    color: $font_black;
    margin-bottom: 12px;
    text-align: center;
  }

  &__text {
    font-size: 14px;
    color: $font_gray;
    text-align: center;
  }

  &__form {
    margin-top: 24px;

    > .TextFormField {
      margin-bottom: 12px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }
}
