@import './constants';
@import './LoginForm.scss';

.section-heading {
  font-family: $primary_font_family;
  font-size: 40px;
  line-height: 1.25em;
  font-weight: 700;
  text-align: center;
  margin: 30px 20px;

  @media (max-width: map-get($grid-breakpoints, "md")) {
    font-size: 32px;
    margin: 30px 20px;
  }
}

.section-lead {
  font-family: $primary_font_family;
  font-style: normal;
  font-size: 20px;
  line-height: 1.25em;
  color: $purple;
  font-weight: 700;
  text-align: center;
  margin-top: 40px;
}

.section-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0;
}

.feature-name {
  display: inline-block;
  font-family: $primary_font_family;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  margin: 0 0 10px;
}

.feature-description {
  font-family: $primary_font_family;
  font-size: 18px;
  color: #8a8a8e;
  font-weight: 400;
}

.product-name {
  display: inline-block;
  font-family: $primary_font_family;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  margin: 0 0 10px;
  padding: 40px 0 20px 0;
}

.product-cta {
  font-family: $primary_font_family;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: $purple;
}

.product-image {
  max-width: 440px;
  height: 340px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    float: right;
  }
}

.value-name {
  display: inline-block;
  font-family: $primary_font_family;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 10px;
}

.value-icon {
  margin-top: 40px;
  height: 70px;
}

.TracksOverview__cards {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  align-items: center;
  justify-content: center;

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    justify-content: space-between;
  }
}

.TracksOverview__card {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  width: 290px;
  height: 60px;
  padding: 24px 18px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 51px;
  margin-bottom: 8px;
  line-height: 20px;
  transition: background-color 250ms ease;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    margin-right: 16px;
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    width: 300px;
    height: 84px;
    font-size: 18px;
    padding: 24px;
    margin-bottom: 16px;
    margin-right: 0;
  }

  /*   &--purple {
    background-color: $color-purple;

    &:hover {
      background-color: darken($color-purple, 5%);
    }
  } */
}

.TracksOverview__cardText {
  font-family: $primary_font_family;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  width: 100%;
}

.TracksOverview__row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
