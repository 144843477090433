@import "./constants";

.comment {
  &-message-chop {
    max-height: 200px;
    overflow: hidden;
  }

  &-message-chop::after {
    content: '';
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &-profile-img {
    background-color: #f0f0f0;
    height: 40px;
    width: 40px;
    margin-right: 16px;
    border-radius: 50px;
    flex-shrink: 0;
  }

  &-profile-name {
    font-weight: 600;
    font-size: 14px;
  }
}

// Buttons contained in comment toolbar
.CommentButton {
  display: inline-block;
  font-weight: 600;
  color: #888888;
  font-size: 13px;
  line-height: 26px;
  padding: 0 12px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 175ms ease-in-out;
  white-space: nowrap;

  &:hover,
  &:focus {
    background: #f7f7f7;
    color: #333333;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &--outline {
    border: 1px solid #dbdbdb;

    &:hover,
    &:focus {
      border: 1px solid transparent;
    }
  }

  &__icon-left {
    margin-right: 8px;
    margin-left: -4px;
  }
}

.ReplyPreview {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  background: #fafafa;
  border-top: 1px solid #eeeeee;
  width: 100%;

  &:hover {
    background: #f6f6f6;
  }

  &__reply {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__user {
    font-weight: 500;
    margin-right: 12px;
    white-space: nowrap;
  }

  &__reply-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    p {
      margin: 0;
    }
  }

  &__more {
    padding-left: 42px;
    margin-top: 4px;
    display: inline-block;

    svg {
      margin-right: 8px;
    }
  }
}

.TopAnswerBadge {
  color: #e8b80e;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: $primary_font_family;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 1rem;
}
