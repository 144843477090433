@import './constants.scss';

.SignupModal {
  background: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: $z_index_max;
  display: flex;
  box-shadow: 0 -3px 11px 0 rgba(0, 0, 0, 0.27);
  width: 100%;
  max-width: 600px;
  max-height: 600px;
  overflow: hidden;
  padding-top: 0;
  border-radius: $border-lg;
  animation: scaleUp 750ms ease-in-out forwards;

  &:focus {
    outline: 0;
  }

  @media (max-width: 500px) {
    // At very small widths, cover the whole screen
    min-width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
    max-height: none;
    overflow-y: scroll;
    border-radius: 0;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: $z_index_highest;
    animation: fadeIn 500ms ease-in-out forwards;
    opacity: 0;
  }
}
