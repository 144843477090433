.Placeholder {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__line {
    background: #eeeeee;
    border-radius: 10px;
    height: 16px;
    width: 80%;
    margin-bottom: 12px;
  }

  &__line:nth-child(2n) {
    width: 50%;
  }

  &__line:last-child {
    margin-bottom: 0;
  }

  &--animated {
    animation: pulse alternate-reverse 500ms ease;
  }
}

.QuestionListPlaceholder {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0;

  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
  }

  &__lines {
    width: 65%;
  }

  &__line {
    background: #eeeeee;
    height: 18px;
    border-radius: 4px;
  }

  &__line:nth-child(2n) {
    width: 50%;
    margin-top: 0.5rem;
  }

  &__circles {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__circle {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    background: #eeeeee;
    border: 2px solid #ffffff;
    margin-right: -6px;
  }

  &__circle:last-child {
    margin-right: 0;
  }
}

@keyframes pulse {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}
