@import "./constants";

.CommentEditor {
  min-height: 250px;
  width: 100%;

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    color: #a5a5a5;
  }

  .public-DraftEditor-content {
    min-height: 100px;
  }

  &__toolbar {
    display: none;
    align-items: center;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      display: flex;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    border-top: 1px solid #efefef;
  }
}

.ReplyEditor {
  .CommentEditor {
    min-height: 50px;
    border: 0;
    margin-bottom: 0;

    .public-DraftEditor-content {
      min-height: 30px;
    }
  }
}

[class*="draftJsToolbar__buttonWrapper__"] {
  display: inline-block;
}

[class*="draftJsToolbar__button__"] {
  background: transparent;
  color: #888888;
  border: 0;
  font-weight: 500;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  padding-top: 2px;
  height: 34px;
  width: 36px;

  svg {
    fill: #888888;
  }

  &:hover,
  &:focus {
    background: #f3f3f3;
    outline: 0; /* reset for :focus */
  }
}

[class*="draftJsToolbar__active__"] {
  background: #efefef;
  color: #444444;

  svg {
    fill: #444444;
  }
}

[class*="draftJsToolbar__separator__"] {
  display: inline-block;
  border-right: 1px solid #dddddd;
  height: 24px;
  margin: 0 0.5em;
}

[class*="draftJsToolbar__toolbar__"] {
  ::after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 4px;
    margin-left: -4px;
  }

  ::before {
    border-color: rgba(221, 221, 221, 0);
    border-top-color: #dddddd;
    border-width: 6px;
    margin-left: -6px;
  }
}
