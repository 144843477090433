$font_black: #2d3547;
$font_gray: #818a9a;

$brand_green: #03cb9a;
$brand_light_green: #1bc4ae;
$brand_blue: #640df0;
$brand_dark_blue: #4107b4;
$purple: #5524e0;
$accent_gold: #e2cb85;
$bg_white: #f9f9f9;
$light_grey: #8a8a8e;
$med_grey: #616161;
$dark_grey: #1c1b24;
$green: #16745f;
$white: #ffffff;
$border_gray: #e8eaed;

$heading_font_family: 'Roboto', 'Avenir Next', Helvetica, Arial, sans-serif;
$body_font_family: 'Open Sans', Arial, sans-serif;
$primary_font_family: 'Inter', sans-serif;

$z_index_normal: 0;
$z_index_high: 10; // Cover normal content
$z_index_higher: 20; // Cover page content
$z_index_highest: 50; // Cover entire page (modal backdrop)
$z_index_max: 60; // Cover everything (modal)

$theme-colors: (
  primary: $purple,
  secondary: $light_grey,
  danger: #ff5555,
  success: $brand_green,
  warning: $accent_gold,
  info: #17a2b8
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
$border-lg: 5px;

$shadow-lg: 0 10px 40px rgba(0, 0, 0, 0.14);
$shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.07);

* {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@mixin panel($rounded: true) {
  background: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

  @if $rounded {
    border-radius: 6px;
  }
}

@mixin panelLight() {
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 5px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.03);
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.resize-iframe iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}

@keyframes breathe {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-breathe {
  animation: breathe 2s ease-in-out infinite;
}
