@import "./constants";

.LessonPage {
  code {
    background: rgba($purple, 0.07);
    color: $purple;
  }

  &--blocked {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      pointer-events: none;
      background:
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1)
        );
    }
  }
}
