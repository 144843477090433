@import './constants';

.PromoCountdown {
  &__dateBox {
    background: $border_gray;
    border-radius: 12px;
    padding: 10px;
    margin-right: 10px;
    width: 80px;
    text-align: center;
  }

  &__dateRow {
    display: flex;

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      justify-content: center;
    }
  }

  &__dateNumber {
    font-family: $primary_font_family;
    font-weight: 600;
    font-size: 45px;
  }

  &__dateUnit {
    font-family: $primary_font_family;
    font-weight: 600;
    font-size: 12px;
    color: $light_grey;
  }

  &__rule {
    border-top: 1px solid $light_grey;
  }
}
