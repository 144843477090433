@import "./constants";

.FAQs {
  @include panel;

  margin: 20px auto 50px;
  overflow: hidden;
  max-width: 1000px;

  &__question {
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: 80px;
    border-top: 1px solid #f0f0f0;
    font-family: $heading_font_family;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
  }

  &__answer {
    padding: 20px 30px 20px;
    background: #fafafa;
    font-size: 15px;
  }

  &__arrow {
    width: 9px;
    height: 11px;
    background: url("./images/triangle.svg") no-repeat;
    background-size: 11px 9px;
    transition: all 300ms ease-in-out;

    &--rotated {
      transform: rotate(90deg);
    }
  }
}
