@import './constants';

.NotFoundPage {
  text-align: center;

  &__panel {
    padding: 50px 0;
    max-width: 505px;
    width: 90%;
    margin: 15px auto;
  }

  &__title {
    margin: 20px auto;
  }
}
