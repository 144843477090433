.FeatureGrid {
  padding: 24px 0;

  &__list {
    margin-top: 20px;
  }

  &__feature {
    margin-bottom: 30px;
    min-height: 150px;
  }
}
