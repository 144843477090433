@import './constants';

.ReferralInfoSettings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  &__title {
    align-self: center;
  }

  &__subtitle {
    align-self: center;
    text-align: center;
    color: $med_grey;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 20px;
  }

  &__divider-text {
    align-self: center;
    font-weight: 500;
    font-size: 14px;
    color: $light_grey;
    margin-top: 15px;
  }

  &__form {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;

    &__input {
      min-width: 100%;

      .TextFormField input {
        background-color: white;
      }
    }

    &__button {
      margin-top: 10px;
    }

    &__success-message {
      color: $brand_blue;
      font-weight: bold;
      max-width: 500px;
      width: 100%;
      margin: 5px auto 0;
    }
  }
}
