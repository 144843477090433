@import './constants';

.plan-container {
  width: 30%;
  min-width: 375px;
  margin: 80px 20px 0 20px;
  text-align: initial;

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    margin: 40px 40px 0 40px;
  }
}
