.CancelModal {
  &__content {
    padding: 20px 32px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__input {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    padding: 8px 12px;
  }

  .CancelModalButton {
    margin: 0 32px 20px;
  }
}

.CancelModalForm {
  position: relative;
  max-width: 700px;
  max-height: 90vh;

  .Modal__body {
    overflow-y: scroll;
  }

  &__error {
    margin-top: 3px;
    color: red;
    font-size: 13px;
  }

  &__confirmation {
    text-align: center;

    a {
      display: block;
    }
  }
}
