@import './constants';

.ProfileCard {
  box-shadow: $shadow-lg;
  border-radius: $border-lg;
  width: 100%;
  padding: 22px;
  font-family: $heading_font_family;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  &__top {
    display: flex;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    color: $dark_grey;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 500;
    color: $light_grey;
    margin-bottom: 0.5em;
  }

  &__description {
    font-size: 16px;
    color: $light_grey;
  }

  &__img {
    height: 64px;
    width: 64px;
    min-width: 64px;
    object-fit: cover;
    border-radius: 40px;
    margin-right: 20px;
  }

  &__body {
    padding-top: 16px;
    font-size: 16px;
    line-height: 26px;
    flex-grow: 1;
  }

  .Button {
    margin-top: 16px;
    text-align: center;
  }
}
