@import './constants';

.Testimonial {
  @include panel();

  font-family: $heading_font_family;
  padding: 30px;
  margin: 10px;

  &__author {
    display: flex;
    margin: 10px 0;
  }

  &__author-text {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    justify-content: center;
  }

  &__author-name {
    font-weight: 600;
    font-size: 18px;
  }

  &__author-title {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  &__img {
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 50px;
    margin-right: 20px;
  }

  &__text {
    font-size: 16px;
    padding-bottom: 30px;
    color: #8a8a8e;
  }

  .stars {
    padding: 15px 0;
  }

  .star {
    margin: 0 3px;
    background-image: url("./images/star.svg");
    background-size: 20px 20px;
    display: inline-block;
    height: 20px;
    width: 20px;
  }
}
