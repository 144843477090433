@import "./constants";

.GuidesPage {
  width: 100%;
  padding: 30px 0 40px;
  background: #ffffff;
  justify-content: center;
  text-align: center;

  &__top {
    font-family: $heading_font_family;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 74px;
    max-width: map-get($grid-breakpoints, "lg");
    margin: auto;

    @media (max-width: map-get($grid-breakpoints, "sm")) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__coming {
    text-align: left;
    background: #ebebff;
    padding: 30px;
    border-radius: 8px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: map-get($grid-breakpoints, "md")) {
      padding: 20px;
      flex-direction: column;
    }

    &__content {
      max-width: map-get($grid-breakpoints, "md");
    }

    &__title {
      color: $purple;
      font-weight: bold;
      font-size: 24px;
      margin-top: 0;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__button__wrapper {
      @media (min-width: map-get($grid-breakpoints, "md")) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
      }
    }

    &__button {
      position: relative;
      display: block;

      @media (max-width: map-get($grid-breakpoints, "md")) {
        position: relative;
        right: auto;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
