@import './constants';

.LoginForm {
  &--simple {
    // Styles for home page
    border-radius: 10px;
    margin: 0 auto;
    width: 450px;
    max-width: unset;
    padding: 30px 40px 20px;
    text-align: center;
  }

  &__header {
    font-weight: bold;
    text-align: center;
    padding-bottom: 25px;
    font-size: 25px;
  }

  &__panel {
    background: #ffffff;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.137456);
    border-radius: 5px;
    max-width: 400px;
    margin: 15px auto;
    padding: 50px 40px 20px;

    .LoginForm__header {
      font-size: 40px;
    }

    p {
      color: #8a8a8e;
      text-align: center;
    }
  }

  &__heading {
    font-size: 32px;
    font-weight: 500;
    font-family: $heading_font_family;
    display: inline-block;
    margin-bottom: 20px;
  }

  &__divider {
    text-align: center;
    height: 30px;
    margin: 30px 0 0;
    border-top: 1px solid #dddddd;
    display: block;
    position: relative;

    &-text {
      background: white;
      position: relative;
      top: -10px;
      padding: 0 10px;
      font-weight: 500;
      font-size: 14px;
      color: #dddddd;
    }
  }

  &__sso {
    display: flex;
    margin: 0 0 20px;
    text-align: center;

    span {
      color: #333333;
      font-size: 14px;
    }

    .Button:first-child {
      margin-right: 16px;
    }
  }

  &__body > * {
    margin-bottom: 20px;
  }

  &__body > *:last-child {
    margin-bottom: 0;
  }

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    &__sso {
      flex-direction: column;

      .Button:first-child {
        margin-bottom: 10px;
      }
    }
  }

  &__incorrect {
    text-align: center;
    padding: 5px;
    background: lightcoral;
    border-radius: 4px;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
    color: #ffffff;
  }

  &__form {
    input {
      padding: 16px 24px;
      border-radius: 6px;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      height: 3rem;
    }

    .form-group {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__submit {
    text-align: center;
    width: 60%;
    margin: 10px 0 20px;
    height: 47px;
  }

  &--simple &__submit {
    margin-top: 20px;
  }

  &__secondary-text {
    color: #8a8a8e;
    text-align: center;
  }

  &__hero {
    padding: 25px 40px 20px;
    border-radius: 20px;

    .LoginForm__submit {
      margin-top: 10px;
    }
  }

  &__input {
    background: #ffffff;
    border: 1px solid #dedede;
    box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.07);
    border-radius: $border-lg;
    height: 50px;
    font-size: 18px;
    padding: 10px 20px;
  }
}
