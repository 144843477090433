@import 'constants';

$purchase_green: #27ae60;

.CoachingProfilePurchase {
  background-color: #fafafa;
  border: 1px solid $section_border_gray;
  border-radius: 6px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  padding: 20px;

  &__price {
    margin-bottom: -10px;
    color: $font_gray;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: -3px;
    }
  }

  &__original-price {
    font-weight: bold;
    font-size: 24px;
    margin-right: 8px;
    color: $purchase_green;
  }

  &__total-price {
    font-size: 24px;
    font-weight: bold;
    margin-right: 8px;
    text-decoration: line-through;
    color: $font_gray;
  }

  &__checkmark {
    -webkit-mask: url("./images/check.svg") no-repeat center;
    mask: url("./images/check.svg") no-repeat center;
    mask-size: 18px 18px;
    height: 18px;
    width: 18px;
    background-color: $purple;
    margin-right: 8px;
  }

  &__dropdown {
    margin-bottom: 24px;
  }
}
