@import 'constants';

.CoachingSearchPage {
  background: white;

  &__header {
    font-size: 36px;
    color: $font_black;
    font-weight: bold;
    line-height: 54px;
    margin-bottom: 12px;
    margin-top: 52px;

    @media (max-width: map-get($grid-breakpoints, "sm")) {
      font-size: 24px;
      font-weight: bold;
      line-height: 38px;
      margin-top: 30px;
    }
  }

  &__grid-col {
    margin-bottom: 32px;

    @media (max-width: map-get($grid-breakpoints, "sm")) {
      margin-bottom: 16px;
    }
  }
}
