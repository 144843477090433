@import 'constants';

$border_color: #e0e0e0;

.TestimonialCard {
  margin: 0 0.75rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border: 1px solid $border_color;
  border-radius: 10px;
  white-space: nowrap;
  width: 300px;
  flex-shrink: 0;

  &__logoContainer {
    display: flex;
    width: 33.33%;
    height: 5rem;
    margin: 0 1rem;
    justify-content: center;
    background-color: #fafafa;
    border-radius: 6px;
    align-items: center;
  }

  &__logo {
    height: 2.5rem;
    width: 2.5rem;
  }

  &__header {
    font-weight: 600;
    font-size: 1.1rem;
  }

  &__newSalary {
    font-weight: 600;
    font-size: 1.25rem;
  }
}

.TestimonialContainer {
  display: flex;
  flex-direction: column;
  width: 50vw;
  overflow: visible;
}
