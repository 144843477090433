/**
 * This is needed since the Tailwind and Boostrap 'hidden' classes
 * conflict right now and prevent the mobile size from being styled
 */

@import './constants';

.VideoAnswer {
  &__blur {
    filter: blur(5px);
  }

  &__subtitle,
  &__upsell {
    display: none;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      display: flex;
    }
  }

  @media only screen and (max-width: 767px) {
    &__stars {
      display: none;
    }
  }
}
