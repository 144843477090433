.Testimonials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__inner {
    position: relative;
    min-height: 220px;
    width: 100%;
    margin-bottom: 16px;
  }

  .Testimonial {
    position: absolute;
    top: 0;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 500ms ease;

    &.active {
      opacity: 1;
      transform: translateY(0);
      position: relative;
    }
  }

  &__selector {
    z-index: 10;
    display: flex;
    justify-content: center;

    button {
      background: #dddddd;
      border: 0;
      border-radius: 20px;
      height: 8px;
      width: 8px;
      padding: 0;
      cursor: pointer;
      margin: 0 5px;

      &.active {
        background: #aaaaaa;
      }

      &:active,
      &:focus {
        outline: 0;
      }
    }
  }
}
