@import "./constants";

.CoachModal {
  max-width: 800px;

  @media (max-width: map-get($grid-breakpoints, 'md')) {
    width: 100%;
  }

  &__info {
    padding: 32px 25px;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    background-color: #f9f9f9;
    height: 100%;

    &-header {
      font-weight: 500;
      font-size: 18px;
      line-height: 40px;
      margin-bottom: 20px;
    }

    &-features {
      font-size: 16px;
      line-height: 28px;
      padding-left: 18px;

      li {
        margin-bottom: 20px;
      }
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 35px 40px;
    height: 100%;

    &-slider {
      .MuiSlider-colorPrimary {
        color: $purple;
      }

      .MuiSlider-thumb {
        width: 24px;
        height: 24px;
        background: white;
        border: 6px solid $purple;
        margin-top: -8px;
        margin-left: -12px;
      }

      .MuiSlider-root,
      .MuiSlider-rail,
      .MuiSlider-track,
      .MuiSlider-mark {
        height: 8px;
        border-radius: 8px;
      }

      .MuiSlider-rail {
        background-color: #c4c4c4;
      }

      .MuiSlider-mark {
        background-color: white;
        border-radius: unset;
      }

      .MuiSlider-markLabel {
        font-size: 18px;
        line-height: 28px;
        color: #000000;
        margin-top: 5px;
      }
    }
  }
}
