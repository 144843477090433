@import './constants';

.LoginPage,
.SignupPage {
  padding-bottom: 50px;

  &__title {
    text-align: center;

    h1 {
      font-weight: bold;
      padding-bottom: 10px;
    }
  }

  &__panel {
    max-width: 500px;
    background: #ffffff;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.27);
    border-radius: 6px;
    margin: auto;
    padding: 50px 50px 20px;
  }

  h3 {
    text-align: center;
    margin: 0 0 30px;
  }

  &__incorrect {
    text-align: center;
    padding: 5px;
    background: lightcoral;
    border-radius: 4px;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
    color: #ffffff;
  }

  &__box {
    background: white;
    border-radius: 5px;
    padding: 40px 40px 20px;
    border-width: thin;
    border-color: $border_gray;
    max-width: 600px;
    margin: 35px auto;
  }

  &__footer {
    text-align: center;

    a {
      color: $brand_blue;
      text-decoration: underline;
    }

    p {
      color: #8a8a8e;
      margin: 5px 0;
    }
  }
}
