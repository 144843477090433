@import './constants';

body {
  background: $bg_white;
  font-family: $primary_font_family;
  margin: 0;
  padding: 0;
}

body.using-mouse :focus {
  outline: none !important;
}

// Allows the Header to prevent body scrolling while it is expanded
body.noscroll {
  overflow: hidden;
}

/* Resets */

* {
  box-sizing: border-box;
}

/* This overrides Bootstrap's default font size which is 10px */
html {
  font-size: 16px;
}

a,
a:focus,
a:hover,
a:visited,
a:active {
  text-decoration: none;
  color: inherit;
}

/* Loader */

.loader {
  position: relative;
  height: 30px;
  width: 30px;
  margin: 25px auto;
  opacity: 0;
  animation: fadeIn 0.2s forwards;
}

.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-top-color: rgba(255, 255, 255, 0.8);
  animation: spinner 0.5s linear infinite;
}

.loader.blue::before {
  border: 2px solid rgba(74, 144, 226, 0.4);
  border-top-color: rgba(74, 144, 226, 0.8);
}

svg {
  display: inline;
}
