@import './constants';

.DisplayWrapper {
  @each $size, $unit in $grid-breakpoints {
    &-#{$size}-hide {
      @media (min-width: $unit) {
        display: none;
      }
    }

    &-#{$size}-show {
      @media (min-width: $unit) {
        display: block;
      }
    }
  }
}
