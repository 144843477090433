.kg-audio-card,.kg-audio-card * {
  box-sizing: border-box
}

.kg-audio-card {
  display: flex;
  width: 100%;
  min-height: 96px;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(124,139,154,.25)
}

.kg-audio-card+.kg-audio-card {
  margin-top: 1em
}

.kg-audio-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  margin: 8px;
  background: 0 0;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 2px
}

.kg-audio-thumbnail.placeholder {
  background: var(--ghost-accent-color)
}

.kg-audio-thumbnail.placeholder svg {
  width: 24px;
  height: 24px;
  fill: #fff
}

.kg-audio-player-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  --seek-before-width: 0%;
  --volume-before-width: 100%;
  --buffered-width: 0%
}

.kg-audio-title {
  width: 100%;
  margin: 8px 0 0;
  padding: 8px 12px;
  border: 0;
  font-family: inherit;
  font-size: 1.15em;
  font-weight: 700;
  line-height: 1.15em;
  background: 0 0
}

.kg-audio-player {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 8px 12px
}

.kg-audio-current-time,.kg-audio-time {
  font-family: inherit;
  font-size: .85em;
  font-weight: 500;
  line-height: 1.4em;
  white-space: nowrap
}

.kg-audio-current-time {
  min-width: 38px;
  padding: 0 4px
}

.kg-audio-time {
  width: 56px;
  color: #ababab
}

.kg-audio-duration {
  padding: 0 4px
}

.kg-audio-pause-icon,.kg-audio-play-icon {
  position: relative;
  bottom: 1px;
  padding: 0 4px 0 0;
  font-size: 0;
  background: 0 0
}

.kg-audio-hide {
  display: none!important
}

.kg-audio-pause-icon svg,.kg-audio-play-icon svg {
  width: 14px;
  height: 14px;
  fill: currentColor
}

.kg-audio-seek-slider {
  flex-grow: 1;
  margin: 0 4px
}

@media (max-width: 640px) {
  .kg-audio-seek-slider {
      display:none
  }
}

.kg-audio-playback-rate {
  min-width: 37px;
  padding: 0 4px;
  font-family: inherit;
  font-size: .85em;
  font-weight: 600;
  line-height: 1.4em;
  text-align: left;
  background: 0 0;
  white-space: nowrap
}

@media (max-width: 640px) {
  .kg-audio-playback-rate {
      padding-left:8px
  }
}

.kg-audio-mute-icon,.kg-audio-unmute-icon {
  position: relative;
  bottom: -1px;
  padding: 0 4px;
  font-size: 0;
  background: 0 0
}

@media (max-width: 640px) {
  .kg-audio-mute-icon,.kg-audio-unmute-icon {
      margin-left:auto
  }
}

.kg-audio-mute-icon svg,.kg-audio-unmute-icon svg {
  width: 16px;
  height: 16px;
  fill: currentColor
}

.kg-audio-volume-slider {
  width: 80px
}

@media (max-width: 400px) {
  .kg-audio-volume-slider {
      display:none
  }
}

.kg-audio-seek-slider::before,.kg-audio-volume-slider::before {
  content: "";
  position: absolute;
  left: 0;
  width: var(--seek-before-width)!important;
  height: 4px;
  cursor: pointer;
  background-color: currentColor;
  border-radius: 2px
}

.kg-audio-volume-slider::before {
  width: var(--volume-before-width)!important
}

.kg-audio-player-container input[type=range] {
  position: relative;
  -webkit-appearance: none;
  background: 0 0;
  height: auto;
  padding: 0;
  border: 0
}

.kg-audio-player-container input[type=range]:focus,.kg-video-card input[type=range]:focus {
  outline: 0
}

.kg-audio-player-container input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none
}

.kg-audio-player-container button,.kg-video-card button {
  display: flex;
  align-items: center;
  border: 0;
  cursor: pointer
}

.kg-audio-player-container input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: rgba(124,139,154,.25);
  border-radius: 2px
}

.kg-audio-player-container input[type=range]::-webkit-slider-thumb {
  position: relative;
  box-sizing: content-box;
  width: 13px;
  height: 13px;
  margin: -5px 0 0;
  border: 0;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0,0,0,.08),0 1px 4px rgba(0,0,0,.24)
}

.kg-audio-player-container input[type=range]:active::-webkit-slider-thumb {
  transform: scale(1.2)
}

.kg-audio-player-container input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: rgba(124,139,154,.25);
  border-radius: 2px
}

.kg-audio-player-container input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2px
}

.kg-audio-player-container input[type=range]::-moz-range-thumb {
  box-sizing: content-box;
  width: 13px;
  height: 13px;
  border: 0;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0,0,0,.08),0 1px 4px rgba(0,0,0,.24)
}

.kg-audio-player-container input[type=range]:active::-moz-range-thumb {
  transform: scale(1.2)
}

.kg-audio-player-container input[type=range]::-ms-track {
  width: 100%;
  height: 3px;
  border: solid transparent;
  color: transparent;
  cursor: pointer;
  background: 0 0
}

.kg-audio-player-container input[type=range]::-ms-fill-lower {
  background: #fff
}

.kg-audio-player-container input[type=range]::-ms-fill-upper {
  background: currentColor
}

.kg-audio-player-container input[type=range]::-ms-thumb {
  box-sizing: content-box;
  width: 13px;
  height: 13px;
  border: 0;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0,0,0,.08),0 1px 4px rgba(0,0,0,.24)
}

.kg-audio-player-container input[type=range]:active::-ms-thumb {
  transform: scale(1.2)
}

.kg-before-after-card>div {
  position: relative;
  margin: 0 auto
}

.kg-before-after-card-image-before {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%
}

.kg-before-after-card .kg-before-after-card-image-after img {
  width: 100%
}

.kg-before-after-card .kg-before-after-card-image-before img {
  max-width: none;
  object-fit: cover
}

.kg-before-after-card input {
  position: absolute;
  top: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  background: 0 0;
  outline: 0;
  margin: 0
}

.kg-before-after-card input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 100%;
  background: #fff;
  cursor: pointer
}

.kg-before-after-card input::-moz-range-thumb {
  width: 5px;
  height: 100%;
  background: #fff;
  cursor: pointer
}

.kg-before-after-card-slider-handle {
  pointer-events: none;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  left: calc(50% - 18px);
  top: calc(50% - 18px);
  display: flex;
  justify-content: center;
  align-items: center
}

.kg-before-after-card-slider-handle:after,.kg-before-after-card-slider-handle:before {
  transform: rotate(-45deg);
  content: "";
  padding: 3px;
  display: inline-block;
  border: solid #5d5d5d;
  border-width: 0 2px 2px 0
}

.kg-before-after-card-slider-handle:before {
  transform: rotate(135deg)
}

.kg-blockquote-alt {
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.7em;
  text-align: center;
  padding: 0 2.5em
}

@media (max-width: 800px) {
  .kg-blockquote-alt {
      font-size:1.4em;
      padding-left: 2em;
      padding-right: 2em
  }
}

@media (max-width: 600px) {
  .kg-blockquote-alt {
      font-size:1.2em;
      padding-left: 1.75em;
      padding-right: 1.75em
  }
}

.kg-bookmark-card,.kg-bookmark-card * {
  box-sizing: border-box
}

.kg-bookmark-card,.kg-bookmark-publisher {
  position: relative;
  width: 100%
}

.kg-bookmark-card a.kg-bookmark-container,.kg-bookmark-card a.kg-bookmark-container:hover {
  display: flex;
  text-decoration: none;
  border-radius: 3px;
  border: 1px solid rgb(124 139 154/25%);
  overflow: hidden;
  color: inherit
}

.kg-bookmark-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  overflow: hidden
}

.kg-bookmark-title {
  font-size: 1.5rem;
  line-height: 1.4em;
  font-weight: 600
}

.kg-bookmark-description {
  display: -webkit-box;
  font-size: 1.4rem;
  line-height: 1.5em;
  margin-top: 3px;
  font-weight: 400;
  max-height: 44px;
  overflow-y: hidden;
  opacity: .7;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical
}

.kg-bookmark-metadata {
  display: flex;
  align-items: center;
  margin-top: 22px;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  white-space: nowrap
}

.kg-bookmark-metadata>:not(img) {
  opacity: .7
}

.kg-bookmark-icon {
  width: 20px;
  height: 20px;
  margin-right: 6px
}

.kg-bookmark-author {
  display: inline
}

.kg-bookmark-publisher {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 240px;
  white-space: nowrap;
  display: block;
  line-height: 1.65em
}

.kg-bookmark-metadata>span:nth-of-type(2) {
  font-weight: 400
}

.kg-bookmark-metadata>span:nth-of-type(2):before {
  content: "•";
  margin: 0 6px
}

.kg-bookmark-metadata>span:last-of-type {
  overflow: hidden;
  text-overflow: ellipsis
}

.kg-bookmark-thumbnail {
  position: relative;
  flex-grow: 1;
  min-width: 33%
}

.kg-bookmark-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 2px 2px 0
}

.kg-button-card,.kg-button-card * {
  box-sizing: border-box
}

.kg-button-card,.kg-button-card a.kg-btn {
  display: flex;
  position: static;
  align-items: center
}

.kg-button-card {
  width: 100%;
  justify-content: center
}

.kg-button-card.kg-align-left {
  justify-content: flex-start
}

.kg-button-card a.kg-btn {
  padding: 0 1.2em;
  height: 2.4em;
  line-height: 1em;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-size: .95em;
  font-weight: 600;
  text-decoration: none;
  border-radius: 5px;
  transition: opacity .2s ease-in-out
}

.kg-button-card a.kg-btn:hover {
  opacity: .85
}

.kg-button-card a.kg-btn-accent {
  background-color: var(--ghost-accent-color);
  color: #fff
}

.kg-callout-card,.kg-callout-card * {
  box-sizing: border-box
}

.kg-callout-card {
  display: flex;
  padding: 1.2em 1.6em;
  border-radius: 3px
}

.kg-callout-card-grey {
  background: rgba(124,139,154,.13)
}

.kg-callout-card-white {
  background: 0 0;
  box-shadow: inset 0 0 0 1px rgba(124,139,154,.25)
}

.kg-callout-card-blue {
  background: rgba(33,172,232,.12)
}

.kg-callout-card-green {
  background: rgba(52,183,67,.12)
}

.kg-callout-card-yellow {
  background: rgba(240,165,15,.13)
}

.kg-callout-card-red {
  background: rgba(209,46,46,.11)
}

.kg-callout-card-pink {
  background: rgba(225,71,174,.11)
}

.kg-callout-card-purple {
  background: rgba(135,85,236,.12)
}

.kg-callout-card-accent {
  background: var(--ghost-accent-color);
  color: #fff
}

.kg-callout-card.kg-callout-card-accent a {
  color: #fff;
  text-decoration: underline
}

.kg-callout-card div.kg-callout-emoji {
  padding-right: .8em;
  line-height: 1.25em;
  font-size: 1.15em
}

.kg-callout-card div.kg-callout-text {
  font-size: .95em;
  line-height: 1.5em
}

.kg-callout-card+.kg-callout-card {
  margin-top: 1em
}

.kg-file-card,.kg-file-card * {
  box-sizing: border-box
}

.kg-file-card {
  display: flex
}

.kg-file-card a.kg-file-card-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  color: inherit;
  padding: 6px;
  min-height: 92px;
  border: 1px solid rgb(124 139 154/25%);
  border-radius: 3px;
  transition: all ease-in-out .35s;
  text-decoration: none;
  width: 100%
}

.kg-file-card a.kg-file-card-container:hover {
  border: 1px solid rgb(124 139 154/35%)
}

.kg-file-card-contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4px 8px;
  width: 100%
}

.kg-file-card-title {
  font-size: 1.15em;
  font-weight: 700;
  line-height: 1.3em
}

.kg-file-card-caption {
  font-size: .95em;
  line-height: 1.3em;
  opacity: .6
}

.kg-file-card-title+.kg-file-card-caption {
  margin-top: -3px
}

.kg-file-card-metadata {
  display: inline;
  font-size: .825em;
  line-height: 1.3em;
  margin-top: 2px
}

.kg-file-card-filename {
  display: inline;
  font-weight: 500
}

.kg-file-card-filesize {
  display: inline-block;
  font-size: .925em;
  opacity: .6
}

.kg-file-card-filesize:before {
  display: inline-block;
  content: "•";
  margin-right: 4px
}

.kg-file-card-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-width: 80px;
  height: 100%
}

.kg-file-card-icon:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: currentColor;
  opacity: .06;
  transition: opacity ease-in-out .35s;
  border-radius: 2px
}

.kg-file-card a.kg-file-card-container:hover .kg-file-card-icon:before {
  opacity: .08
}

.kg-file-card-icon svg {
  width: 24px;
  height: 24px;
  color: var(--ghost-accent-color)
}

.kg-file-card-medium a.kg-file-card-container {
  min-height: 72px
}

.kg-file-card-medium .kg-file-card-caption {
  opacity: 1;
  font-weight: 500
}

.kg-file-card-small a.kg-file-card-container {
  align-items: center;
  min-height: 52px
}

.kg-file-card-small .kg-file-card-metadata {
  font-size: 1em;
  margin-top: 0
}

.kg-file-card-small .kg-file-card-icon svg {
  width: 20px;
  height: 20px
}

.kg-file-card+.kg-file-card {
  margin-top: 1em
}

.kg-gallery-card,.kg-gallery-card * {
  box-sizing: border-box
}

.kg-gallery-card,.kg-image-card {
  --gap: 1.2rem
}

.kg-gallery-card:not(.kg-card-hascaption)+.kg-gallery-card,.kg-gallery-card:not(.kg-card-hascaption)+.kg-image-card,.kg-image-card:not(.kg-card-hascaption)+.kg-gallery-card,.kg-image-card:not(.kg-card-hascaption)+.kg-image-card {
  margin-top: var(--gap)
}

.kg-gallery-container {
  position: relative
}

.kg-gallery-row {
  display: flex;
  flex-direction: row;
  justify-content: center
}

.kg-gallery-image img {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%
}

.kg-gallery-row:not(:first-of-type) {
  margin: var(--gap)0 0
}

.kg-gallery-image:not(:first-of-type) {
  margin: 0 0 0 var(--gap)
}

@media (max-width: 600px) {
  .kg-gallery-card,.kg-image-card {
      --gap:0.6rem
  }
}

.kg-header-card,.kg-header-card * {
  box-sizing: border-box
}

.kg-header-card {
  padding: 12vmin 4em;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center
}

.kg-header-card.kg-size-small {
  padding-top: 14vmin;
  padding-bottom: 14vmin;
  min-height: 40vh
}

.kg-header-card.kg-size-large {
  padding-top: 18vmin;
  padding-bottom: 18vmin;
  min-height: 80vh
}

.kg-header-card.kg-align-left {
  text-align: left;
  align-items: flex-start
}

.kg-header-card.kg-style-dark {
  background: #151515;
  color: #fff
}

.kg-header-card.kg-style-light {
  background-color: #fafafa
}

.kg-header-card.kg-style-accent {
  background-color: var(--ghost-accent-color)
}

.kg-header-card.kg-style-image {
  position: relative;
  background-color: #e7e7e7;
  background-size: cover;
  background-position: center
}

.kg-header-card.kg-style-image::before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg,transparent,rgba(0,0,0,.2))
}

.kg-header-card h2.kg-header-card-header {
  font-size: 5em;
  font-weight: 700;
  line-height: 1.1em;
  letter-spacing: -.01em;
  margin: 0
}

.kg-header-card h2.kg-header-card-header strong {
  font-weight: 800
}

.kg-header-card.kg-size-small h2.kg-header-card-header {
  font-size: 4em
}

.kg-header-card.kg-size-large h2.kg-header-card-header {
  font-size: 6em
}

.kg-header-card h3.kg-header-card-subheader {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.4em;
  margin: 0;
  max-width: 40em
}

.kg-header-card h2+h3.kg-header-card-subheader {
  margin: .35em 0 0
}

.kg-header-card h3.kg-header-card-subheader strong {
  font-weight: 600
}

.kg-header-card.kg-size-small h3.kg-header-card-subheader {
  font-size: 1.25em
}

.kg-header-card.kg-size-large h3.kg-header-card-subheader {
  font-size: 1.75em
}

.kg-header-card:not(.kg-style-light) h2.kg-header-card-header,.kg-header-card:not(.kg-style-light) h3.kg-header-card-subheader {
  color: #fff
}

.kg-header-card.kg-style-accent h3.kg-header-card-subheader,.kg-header-card.kg-style-image h3.kg-header-card-subheader,.kg-product-card-rating-active.kg-product-card-rating-star svg {
  opacity: 1
}

.kg-header-card.kg-style-image a.kg-header-card-button,.kg-header-card.kg-style-image h2.kg-header-card-header,.kg-header-card.kg-style-image h3.kg-header-card-subheader {
  z-index: 999
}

.kg-header-card h2.kg-header-card-header a,.kg-header-card h3.kg-header-card-subheader a {
  color: var(--ghost-accent-color)
}

.kg-header-card.kg-style-accent h2.kg-header-card-header a,.kg-header-card.kg-style-accent h3.kg-header-card-subheader a,.kg-header-card.kg-style-image h2.kg-header-card-header a,.kg-header-card.kg-style-image h3.kg-header-card-subheader a {
  color: #fff
}

.kg-header-card a.kg-header-card-button {
  display: flex;
  position: static;
  align-items: center;
  fill: #fff;
  background: #fff;
  border-radius: 3px;
  outline: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-size: 1.05em;
  font-weight: 600;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  letter-spacing: .2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #151515;
  height: 2.7em;
  padding: 0 1.2em;
  transition: opacity .2s ease
}

.kg-header-card h2+a.kg-header-card-button,.kg-header-card h3+a.kg-header-card-button {
  margin: 1.75em 0 0
}

.kg-header-card a.kg-header-card-button:hover {
  opacity: .85
}

.kg-header-card.kg-size-large a.kg-header-card-button {
  font-size: 1.1em;
  height: 2.9em
}

.kg-header-card.kg-size-large h2+a.kg-header-card-button,.kg-header-card.kg-size-large h3+a.kg-header-card-button {
  margin-top: 2em
}

.kg-header-card.kg-size-small a.kg-header-card-button {
  height: 2.4em;
  font-size: 1em
}

.kg-header-card.kg-size-small h2+a.kg-header-card-button,.kg-header-card.kg-size-small h3+a.kg-header-card-button {
  margin-top: 1.5em
}

.kg-header-card.kg-style-dark a.kg-header-card-button,.kg-header-card.kg-style-image a.kg-header-card-button {
  background: #fff;
  color: #151515
}

.kg-header-card.kg-style-light a.kg-header-card-button {
  background: var(--ghost-accent-color);
  color: #fff
}

.kg-header-card.kg-style-accent a.kg-header-card-button {
  background: #fff;
  color: #151515
}

@media (max-width: 640px) {
  .kg-header-card {
      padding-left:1em;
      padding-right: 1em
  }

  .kg-header-card h2.kg-header-card-header {
      font-size: 3.5em
  }

  .kg-header-card.kg-size-large h2.kg-header-card-header {
      font-size: 4em
  }

  .kg-header-card.kg-size-small h2.kg-header-card-header {
      font-size: 3em
  }

  .kg-header-card h3.kg-header-card-subheader {
      font-size: 1.25em
  }

  .kg-header-card.kg-size-large h3.kg-header-card-subheader {
      font-size: 1.5em
  }

  .kg-header-card.kg-size-small h3.kg-header-card-subheader {
      font-size: 1em
  }
}

.kg-nft-card,.kg-nft-card * {
  box-sizing: border-box
}

.kg-nft-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto
}

.kg-nft-card a.kg-nft-card-container {
  position: static;
  display: flex;
  flex: auto;
  flex-direction: column;
  text-decoration: none;
  font-family: -apple-system,BlinkMacSystemFont,"avenir next",avenir,"helvetica neue",helvetica,ubuntu,roboto,noto,"segoe ui",arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0 2px 6px -2px rgb(0 0 0/10%),0 0 1px rgb(0 0 0/40%);
  width: 100%;
  max-width: 512px;
  color: #222;
  background: #fff;
  border-radius: 5px;
  transition: none
}

.kg-nft-card * {
  position: static
}

.kg-nft-metadata {
  padding: 20px;
  width: 100%
}

.kg-nft-image {
  border-radius: 5px 5px 0 0;
  width: 100%
}

.kg-nft-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px
}

.kg-nft-header h4.kg-nft-title {
  font-family: inherit;
  font-size: 19px;
  font-weight: 700;
  line-height: 1.3em;
  min-width: unset;
  max-width: unset;
  margin: 0;
  color: #222
}

.kg-nft-opensea-logo {
  margin-top: 2px;
  width: 100px;
  object-fit: scale-down
}

.kg-nft-card p.kg-nft-description,.kg-nft-creator {
  font-family: inherit;
  line-height: 1.4em;
  margin: 4px 0 0;
  color: #ababab
}

.kg-nft-creator span {
  font-weight: 500;
  color: #222
}

.kg-nft-card p.kg-nft-description {
  font-size: 14px;
  margin: 20px 0 0;
  color: #222
}

.kg-product-card,.kg-product-card * {
  box-sizing: border-box
}

.kg-product-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%
}

.kg-product-card-container {
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;
  grid-row-gap: 16px;
  background: 0 0;
  max-width: 550px;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px rgb(124 139 154/25%)
}

.kg-product-card-image {
  grid-column: 1/3;
  justify-self: center
}

.kg-product-card-title-container {
  grid-column: 1/2
}

.kg-product-card h4.kg-product-card-title {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.4em;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.15em
}

.kg-product-card-description {
  grid-column: 1/3
}

.kg-product-card .kg-product-card-description ol,.kg-product-card .kg-product-card-description p,.kg-product-card .kg-product-card-description ul {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-size: .95em;
  line-height: 1.5em;
  opacity: .7;
  margin-bottom: 0
}

.kg-product-card .kg-product-card-description p:first-of-type {
  margin-top: -4px
}

.kg-product-card .kg-product-card-description ol,.kg-product-card .kg-product-card-description p:not(:first-of-type),.kg-product-card .kg-product-card-description ul {
  margin-top: .95em
}

.kg-product-card .kg-product-card-description li+li,.kg-toggle-card li+li {
  margin-top: .5em
}

.kg-product-card-rating {
  display: flex;
  align-items: center;
  grid-column: 2/3;
  align-self: start;
  justify-self: end;
  padding-left: 16px
}

@media (max-width: 400px) {
  .kg-product-card-title-container {
      grid-column:1/3
  }

  .kg-product-card-rating {
      grid-column: 1/3;
      justify-self: start;
      margin-top: -15px;
      padding-left: 0
  }
}

.kg-product-card-rating-star {
  height: 28px;
  width: 20px
}

.kg-product-card-rating-star svg {
  width: 16px;
  height: 16px;
  fill: currentColor;
  opacity: .15
}

.kg-product-card a.kg-product-card-button {
  justify-content: center;
  grid-column: 1/3;
  display: flex;
  position: static;
  align-items: center;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-size: .95em;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  width: 100%;
  height: 2.4em;
  border-radius: 5px;
  padding: 0 1.2em;
  transition: opacity .2s ease-in-out
}

.kg-product-card a.kg-product-card-btn-accent {
  background-color: var(--ghost-accent-color);
  color: #fff
}

.kg-toggle-card,.kg-toggle-card * {
  box-sizing: border-box
}

.kg-toggle-card {
  background: 0 0;
  box-shadow: inset 0 0 0 1px rgba(124,139,154,.25);
  border-radius: 4px;
  padding: 1.2em
}

.kg-toggle-card[data-kg-toggle-state=close] .kg-toggle-content {
  height: 0;
  overflow: hidden;
  transition: opacity .5s ease,top .35s ease;
  opacity: 0;
  top: -.5em;
  position: relative
}

.kg-toggle-content {
  height: auto;
  opacity: 1;
  transition: opacity 1s ease,top .35s ease;
  top: 0;
  position: relative
}

.kg-toggle-card[data-kg-toggle-state=close] svg {
  transform: unset
}

.kg-toggle-heading {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start
}

.kg-toggle-card h4.kg-toggle-heading-text {
  font-size: 1.15em;
  font-weight: 700;
  line-height: 1.3em;
  margin-top: 0;
  margin-bottom: 0
}

.kg-toggle-content p:first-of-type {
  margin-top: .5em
}

.kg-toggle-card .kg-toggle-content ol,.kg-toggle-card .kg-toggle-content p,.kg-toggle-card .kg-toggle-content ul {
  font-size: .95em;
  line-height: 1.5em;
  margin-top: .95em;
  margin-bottom: 0
}

.kg-toggle-card-icon {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  background: 0 0;
  border: 0
}

.kg-toggle-heading svg {
  width: 14px;
  color: rgba(124,139,154,.5);
  transition: all .3s;
  transform: rotate(-180deg)
}

.kg-toggle-heading path {
  fill: none;
  stroke: currentcolor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5;
  fill-rule: evenodd
}

.kg-toggle-card+.kg-toggle-card {
  margin-top: 1em
}

.kg-video-card,.kg-video-card * {
  box-sizing: border-box
}

.kg-video-card {
  position: relative;
  --seek-before-width: 0%;
  --volume-before-width: 100%;
  --buffered-width: 0%
}

.kg-video-card video {
  display: block;
  max-width: 100%;
  height: auto
}

.kg-video-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center
}

.kg-video-large-play-icon,.kg-video-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .2s ease-in-out
}

.kg-video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(180deg,rgba(0,0,0,.3)0,transparent 70%,transparent 100%);
  z-index: 999
}

.kg-video-large-play-icon {
  width: 72px;
  height: 72px;
  padding: 0;
  background: rgba(0,0,0,.5);
  border-radius: 50%
}

.kg-video-large-play-icon svg {
  width: 20px;
  height: auto;
  margin-left: 2px;
  fill: #fff
}

.kg-video-player-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: linear-gradient(transparent,rgba(0,0,0,.5));
  z-index: 999;
  transition: opacity .2s ease-in-out
}

.kg-video-player {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 9999;
  padding: 12px 16px
}

.kg-video-current-time,.kg-video-time {
  font-family: inherit;
  font-size: .85em;
  font-weight: 500;
  line-height: 1.4em;
  white-space: nowrap
}

.kg-video-current-time {
  min-width: 38px;
  padding: 0 4px;
  color: #fff
}

.kg-video-time {
  color: rgba(255,255,255,.6)
}

.kg-video-duration {
  padding: 0 4px
}

.kg-video-pause-icon,.kg-video-play-icon {
  position: relative;
  padding: 0 4px 0 0;
  font-size: 0;
  background: 0 0
}

.kg-video-hide {
  display: none!important
}

.kg-video-hide-animated {
  opacity: 0!important;
  transition: opacity .2s ease-in-out;
  cursor: initial
}

.kg-video-pause-icon svg,.kg-video-play-icon svg {
  width: 14px;
  height: 14px;
  fill: #fff
}

.kg-video-seek-slider {
  flex-grow: 1;
  margin: 0 4px
}

@media (max-width: 520px) {
  .kg-video-seek-slider {
      display:none
  }
}

.kg-video-playback-rate {
  min-width: 37px;
  padding: 0 4px;
  color: #fff;
  font-family: inherit;
  font-size: .85em;
  font-weight: 600;
  line-height: 1.4em;
  text-align: left;
  background: 0 0;
  white-space: nowrap
}

@media (max-width: 520px) {
  .kg-video-playback-rate {
      padding-left:8px
  }
}

.kg-video-mute-icon,.kg-video-unmute-icon {
  position: relative;
  bottom: -1px;
  padding: 0 4px;
  font-size: 0;
  background: 0 0
}

@media (max-width: 520px) {
  .kg-video-mute-icon,.kg-video-unmute-icon {
      margin-left:auto
  }
}

.kg-video-mute-icon svg,.kg-video-unmute-icon svg {
  width: 16px;
  height: 16px;
  fill: #fff
}

.kg-video-volume-slider {
  width: 80px
}

@media (max-width: 300px) {
  .kg-video-volume-slider {
      display:none
  }
}

.kg-video-seek-slider::before,.kg-video-volume-slider::before {
  content: "";
  position: absolute;
  left: 0;
  width: var(--seek-before-width)!important;
  height: 4px;
  cursor: pointer;
  background-color: #ebeef0;
  border-radius: 2px
}

.kg-video-volume-slider::before {
  width: var(--volume-before-width)!important
}

.kg-video-card input[type=range] {
  position: relative;
  -webkit-appearance: none;
  background: 0 0;
  height: auto;
  padding: 0;
  border: 0
}

.kg-video-card input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none
}

.kg-video-card input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: rgba(255,255,255,.2);
  border-radius: 2px
}

.kg-video-card input[type=range]::-webkit-slider-thumb {
  position: relative;
  box-sizing: content-box;
  width: 13px;
  height: 13px;
  margin: -5px 0 0;
  border: 0;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0,0,0,.08),0 1px 4px rgba(0,0,0,.24)
}

.kg-video-card input[type=range]:active::-webkit-slider-thumb {
  transform: scale(1.2)
}

.kg-video-card input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: rgba(255,255,255,.2);
  border-radius: 2px
}

.kg-video-card input[type=range]::-moz-range-progress {
  background: #ebeef0;
  border-radius: 2px
}

.kg-video-card input[type=range]::-moz-range-thumb {
  box-sizing: content-box;
  width: 13px;
  height: 13px;
  border: 0;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0,0,0,.08),0 1px 4px rgba(0,0,0,.24)
}

.kg-video-card input[type=range]:active::-moz-range-thumb {
  transform: scale(1.2)
}

.kg-video-card input[type=range]::-ms-track {
  width: 100%;
  height: 3px;
  border: solid transparent;
  color: transparent;
  cursor: pointer;
  background: 0 0
}

.kg-video-card input[type=range]::-ms-fill-lower {
  background: #fff
}

.kg-video-card input[type=range]::-ms-fill-upper {
  background: #ebeef0
}

.kg-video-card input[type=range]::-ms-thumb {
  box-sizing: content-box;
  width: 13px;
  height: 13px;
  border: 0;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0,0,0,.08),0 1px 4px rgba(0,0,0,.24)
}

.kg-video-card input[type=range]:active::-ms-thumb {
  transform: scale(1.2)
}

/* Fix for YouTube iframes to make them the correct width and aspect ratio */
.kg-card iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}
